
import Vue from 'vue';
import { mapGetters } from 'vuex';
import AtomButton, { ButtonVariant } from '@/components/atoms/AtomButton.vue';
import AtomCircularButton from '@/components/atoms/AtomCircularButton.vue';
import AtomSetup from '@/components/atoms/AtomSetup.vue';
import AtomText from '@/components/atoms/AtomText.vue';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';
import OrganismPageSubHeader from '@/components/organisms/page-header/OrganismPageSubHeader.vue';
import LanguageEnum from '@/enums/settings/LanguageEnum';

export default Vue.extend({
  name: 'TemplateHelp',
  components: {
    AtomButton,
    AtomCircularButton,
    AtomSetup,
    AtomSvgIcon,
    AtomText,
    OrganismPageSubHeader,
  },
  data: () => ({
    ButtonVariant,
    LanguageEnum,
    feedbackDE:
      'mailto:info@twogo.com?subject=Nutzerfeedback%20zu%20twogo&body=Hallo%20twogo%20Team%2C%0D%0A%0D%0Aich%20will%20euch%20Feedback%20zu%20meiner%20Nutzung%20von%20der%20twogo%20App%20geben.%0D%0A%0D%0A%0D%0A%0D%0A-%20Version%20der%20App%2C%20die%20ich%20nutze%3A%0D%0A-%20Mein%20Handymodell%3A%0D%0A%0D%0AFreundliche%20Gr%C3%BC%C3%9Fe%2C%0D%0A',
    feedbackENG:
      'mailto:info@twogo.com?subject=User%20feedback%20for%20twogo&body=Hello%20twogo%20team%2C%0D%0A%0D%0Ahere%20is%20my%20feedback%20after%20using%20twogo%20app%3A%0D%0A%0D%0A%0D%0A%0D%0A-%20My%20app%20version%3A%0D%0A-%20My%20phone%20model%3A%0D%0A%0D%0ABest%20regards%2C%0D%0A',
    reportBugDE:
      'mailto:info@twogo.com?subject=Bug%20in%20der%20twogo%20App&body=Hallo%20twogo%20Team%2C%0D%0A%0D%0Aich%20habe%20folgenden%20Bug%20in%20der%20twogo%20App%20gefunden%3A%0D%0A%0D%0A%0D%0A%0D%0A-%20Meine%20App%20Version%3A%0D%0A-%20Mein%20Handymodell%3A%0D%0A%0D%0AFreundliche%20Gr%C3%BC%C3%9Fe%2C%0D%0A%0D%0A',
    reportBugENG:
      'mailto:info@twogo.com?subject=Bug%20in%20twogo&body=Hello%20twogo%20team%2C%0D%0A%0D%0AI%20have%20found%20following%20bug%20in%20the%20twogo%20app%3A%0D%0A%0D%0A%0D%0A%0D%0A-%20My%20app%20version%3A%0D%0A-%20My%20phone%20model%3A%0D%0A%0D%0ABest%20regards%2C%0D%0A%0D%0A%0D%0A',
    contactSupportDE:
      'mailto:info@twogo.com?subject=twogo%20Support&body=Hallo%20twogo%20Team%2C%0D%0A%0D%0Aich%20brauche%20Unterst%C3%BCntzung%20bei%20folgender%20Frage%3A%0D%0A%0D%0A%0D%0A%0D%0A-%20Mein%20twogo-Konto%3A%0D%0A-%20Mein%20Ger%C3%A4t%20(Marke%20und%20Modell)%3A%0D%0A%0D%0AFreundliche%20Gr%C3%BC%C3%9Fe%2C%0D%0A%0D%0A%0D%0A',
    contactSupportENG:
      'mailto:info@twogo.com?subject=twogo%20support&body=Hello%20twogo%20team%2C%0D%0A%0D%0Aich%20need%20assistance%20with%20following%20problem%3A%0D%0A%0D%0A%0D%0A%0D%0A-%20My%20twogo%20account%3A%0D%0A-%20My%20device%20(brand%20and%20model)%3A%0D%0A%0D%0ABest%20regards%2C%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A',
  }),
  computed: {
    ...mapGetters('user', ['locale']),
  },
  mounted() {
    window.scroll(0, 0);
  },
});
