
import Vue from 'vue';
import i18n from '@/i18n';
import TemplateHelp from '@/components/templates/help/TemplateHelp.vue';

export default Vue.extend({
  name: 'Home',
  components: { TemplateHelp },
  metaInfo: {
    title: ` - ${i18n.t('tabs.help')}`,
  },
});
